/* eslint-disable */
import IMask from 'imask';

export default class Input {
	constructor(input, page) {
		this.input = input;
		this.eventHandlers = page.validator.eventHandlers;
		this.pageClass = page;
		this.name = input.name;
		this.createErrorSchema();
		this.setMask(this.input.getAttribute('data-mask'));
		this.wasChanged = this.input.hasAttribute('data-validate-always');
		this.registerEvent();
		this.DEFAULT_CLASS_NAME = 'error';
	}

	setMask(pattern) {
		if (!pattern || pattern == '') return;
		IMask(this.input, { mask: pattern });
	}

	get schema() {
		return this.input.getAttribute('data-validate');
	}

	get value() {
		if (this.input.type === 'text') {
			return this.input.value;
		} if (this.input.type === 'checkbox') return this.input.checked;
		if (this.input.type === 'file') return this.input.files;
		return this.input.value;
	}

	createErrorSchema() {
		const wrappers = [...this.pageClass.pageElement.querySelectorAll(`[data-error="${this.name}"]`)];
		const textWrappers = [];
		const classWrappers = [];
		[...wrappers].forEach((element) => {
			if (element.hasAttribute('data-error-class')) {
				classWrappers.push({
					element,
					className: element.dataset.errorClass || this.DEFAULT_CLASS_NAME,
				});
			}
			if (element.hasAttribute('data-error-text')) { textWrappers.push(element); }
		});
		this.classWrappers = classWrappers;
		this.textWrappers = textWrappers;
	}

	onErrorsChanged(validBeforeSubmit) {
		if (validBeforeSubmit === true) {
			this.wasChanged = true;
		}

		if (this.hasErrors()) { this.showErrors(); } else { this.hideErrors(); }
	}

	showErrors() {
		[...this.classWrappers].forEach((wrapper) => {
			this.eventHandlers.toogleWrapperClass(wrapper, true);
		});
		[...this.textWrappers].forEach((wrapper) => {
			const errors = this.pageClass.errors.get(this.name);
			this.eventHandlers.printErrorsText(wrapper, errors);
		});
	}

	hideErrors() {
		[...this.classWrappers].forEach((wrapper) => {
			this.eventHandlers.toogleWrapperClass(wrapper, false);
		});

		[...this.textWrappers].forEach((wrapper) => {
			this.eventHandlers.removeErrorsText(wrapper);
		});
	}

	hasErrors() {
		const errorsBag = this.pageClass.errors;
		const { name } = this;

		if (errorsBag.has(name) && this.wasChanged) { return true; }
		return false;
	}

	registerEvent() {
		this.input.addEventListener('keyup', this.usedField.bind(this));
		this.input.addEventListener('keydown', this.usedField.bind(this));
		this.input.addEventListener('click', this.usedField.bind(this));
		this.input.addEventListener('focus', this.usedField.bind(this));
		this.input.addEventListener('change', this.usedField.bind(this));

		this.input.addEventListener('keyup', this.pageClass.validateInput.bind(this.pageClass));
		this.input.addEventListener('keydown', this.pageClass.validateInput.bind(this.pageClass));
		this.input.addEventListener('click', this.pageClass.validateInput.bind(this.pageClass));
		this.input.addEventListener('focus', this.pageClass.validateInput.bind(this.pageClass));
		this.input.addEventListener('change', this.pageClass.validateInput.bind(this.pageClass));
		this.input.addEventListener('change', this.pageClass.validateInput.bind(this.pageClass));
	}

	usedField() {
		if (!this.wasChanged) { this.wasChanged = true; }
	}
}

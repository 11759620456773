/* eslint-disable */
import ValidatorEngine from 'validatorjs';
import Input from './Input';
import Uploader from './Uploader';

export default class Page {
	constructor(page, validator) {
		this.pageElement = page;
		this.Action = validator.ActionClass;
		this.validator = validator;
		this.labelList = this.getlabelList();
		this.inputs = this.getInputs();
		this.actionList = this.getActionList();
		this.actionButton = {};
		this.sequence = page.getAttribute('data-page');
		this.createSchema();
	}

	createSchema() {
		const rules = {};
		[...this.inputs].forEach((input) => {
			if (input.schema)rules[input.name] = input.schema;
		});
		this.schema = rules;
	}

	getlabelList() {
		const label = {};
		[...this.pageElement.querySelectorAll('[data-validate]')].forEach((input) => {
			label[input.name] = input.getAttribute('data-label');
		});
		return label;
	}

	getInputs() {
		const fields = [];
		[...this.pageElement.querySelectorAll('[data-validate]')].forEach((input) => {
			fields.push(new Input(input, this));
		});

		[...this.pageElement.querySelectorAll('[uploader]')].forEach((uploader) => {
			fields.push(new Uploader(uploader, this));
		});

		return fields;
	}

	getActionList() {
		const actionBar = [...this.pageElement.querySelectorAll('[data-action]')];
		const actionList = [];
		const { Action } = this;
		[...actionBar].forEach((button) => {
			const actionName = button.getAttribute('data-action');
			const event = button.hasAttribute('data-action-event') ? button.getAttribute('data-action-event') : 'click';
			actionList.push(new Action(actionName, this, event));
		});
		if (actionList.length === 0) { console.warn('Warning this page does not contain any action!'); }
		return actionList;
	}

	closePage() {
		this.validator.eventHandlers.closePage(this.pageElement);
	}

	openPage() {
		this.validator.eventHandlers.openPage(this.pageElement);
	}

	getValues() {
		const data = {};
		[...this.inputs].forEach((input) => {
			if (input.schema) data[input.name] = input.value;
		});
		return data;
	}

	submitForm() {
		if (this.validator.eventHandlers.submitForm()) { this.validator.submitForm(); }
	}

	setErrors(errors, validBeforeSubmit) {
		this.errors = errors;
		this.inputs.forEach((input) => {
			input.onErrorsChanged(validBeforeSubmit);
		});
	}

	validateInput() {
		this.validate(false).then((value) => {
			if (value) {
				[...this.actionList].forEach((action) => {
					action.unlockAction();
				});
			} else {
				[...this.actionList].forEach((action) => {
					action.disableAction();
				});
			}
		})
			.catch((value) => {
				console.error(value);
			});
	}

	validate(validBeforeSubmit = true) {
		const _this = this;
		return new Promise((resolve) => {
			[..._this.validator.customRules].forEach((rule) => {
				ValidatorEngine.register(rule.name, rule.callbackFn, rule.errorMessage);
			});

			[..._this.validator.asyncRules].forEach((rule) => {
				ValidatorEngine.registerAsync(rule.name, rule.callbackFn);
			});

			ValidatorEngine.useLang(this.validator.lang);

			let langList = {};

			if (this.validator.langList[this.validator.lang]) {
				langList = 	this.validator.langList[this.validator.lang].getLangList();
			} else {
				langList = this.validator.getMessages('lang_code');
			}

			ValidatorEngine.setMessages(this.validator.lang, langList);

			const validation = new ValidatorEngine(this.getValues(), this.schema);
			validation.setAttributeNames(this.labelList);
			validation.passes(() => {
				this.setErrors(validation.errors, validBeforeSubmit);
				resolve(true);
			});
			validation.fails(() => {
				this.setErrors(validation.errors, validBeforeSubmit);
				resolve(false);
			});
		});
	}
}

/* eslint-disable */
/* globals grecaptcha */
import Page from './Page';
import DefaultEventHandlers from './EventHandlers';
import Action from './Action';
import Language from './Language';
import Input from './Input';
import Uploader from './Uploader';
import Recaptcha from './Recaptcha';

(() => {
	const INSTANCES = [];
	/* global FormData */
	class ValidatorForm {
		/**
		 * Constructor
		 *
		 * @param string selector id or element address schould be uniqe
		 * @return Class EventHandlers
		 */
		constructor(selector, {
			EventHandlers = DefaultEventHandlers, actions = Action, langList, defaultLang = 'pl',
		}, baseurl) {
			this.formSelector = selector;
			INSTANCES.push(this);
			this.eventHandlers = new EventHandlers(this);
			this.lang = defaultLang;
			this.baseurl = baseurl;
			this.messages = [];
			this.langList = {};
			this.activePage = 0;
			this.customRules = [];
			if (typeof langList !== 'undefined') {
				this.getLangList(langList);
			} else {
				this.langList[this.lang] = new Language(this.lang, {});
			}
			this.ActionClass = actions;
			document.addEventListener('DOMContentLoaded', this.init.bind(this));
			if (document.readyState === 'complete') this.init();
		}

		static get INSTANCES() {
			return INSTANCES;
		}

		/**
		 * Get EventHandlers Class
		 * allowing override EventHandlers class
		 *
		 * @return Class EventHandlers
		 */
		static get EventHandlers() {
			return DefaultEventHandlers;
		}

		/**
		 * Get Action Class
		 * allowing override Action class
		 *
		 * @return Class Action
		 */
		static get Action() {
			return Action;
		}

		/**
		 * Init ValidatorForm
		 * it needed to be after document loaded
		 *
		 * @return void
		 */
		init() {
			this.customRules = [];
			this.asyncRules = [];
			this.loadExtraRule();
			this.form = document.querySelector(this.formSelector);
			if (typeof this.baseurl === 'undefined') this.baseurl = this.form.getAttribute('data-baseurl');
			this.mode = this.form.getAttribute('data-mode');
			this.formName = this.form.getAttribute('id');
			if (this.form) {
				this.pages = this.getPages();
				this.hasCaptcha = this.form.querySelector('[data-captcha]');
				if (this.hasCaptcha) this.captcha = new Recaptcha(this, this.form.querySelector('[data-captcha]'));
			}
		}

		loadExtraRule() {
			const filesizeFN = function (val, maxSize) {
				if (val.length === 0) return true;
				for (let i = 0, len = val.length; i < len; i++) {
					if (val[i].size > maxSize) return false;
				}
				return true;
			};
			this.registerCustomRule('filesize', filesizeFN, 'Plik jest za duży');

			const uploadernotemptyFN = function (val) {
				if (val.length === 0) return false;
				return true;
			};
			this.registerCustomRule('uploadernotempty', uploadernotemptyFN, 'Proszę dodać chociaż jeden plik');

			const filerequiredFN = function (val, req) {
				if (val.length === 0) return false;
				return true;
			};
			this.registerCustomRule('filerequired', filerequiredFN, 'Plik jest wymagany');

			const filemimeFN = function (val, alowed) {
				if (val.length === 0) return true;
				alowed = alowed.split(',');
				for (let i = 0, len = val.length; i < len; i++) {
					const mime = val[i].name.split('.').pop();
					if (!~alowed.indexOf(mime)) return false;
				}
				return true;
			};
			this.registerCustomRule('filemime', filemimeFN, 'Zabroniony format pliku');

			this.registerCustomRule('reason', (name) => {
				let inputs = document.querySelectorAll('.checkbox-input')
				let valid = false
				inputs.forEach(input => {
					if (input.checked) valid = true
				})
				return valid
			}, 'Wybierz czym jesteś zainteresowany')
		}

		/**
		 * Set validator lang
		 *
		 * @param string $lang lang code (pl-PL)
		 * @return void
		 */
		setLang(lang) {
			this.lang = lang;
		}

		/**
		 * Get Languages
		 * @return void
		 */
		getLangList(langlist) {
			this.langList = {};
			langlist.forEach((lang) => {
				const langObject = new Language(lang.langcode, lang.langlist);
				this.langList[lang.langcode] = langObject;
			});
		}

		/**
		 * Get all pages in form
		 *
		 * @return array list of Page objects
		 */
		getPages() {
			const pages = [...this.form.querySelectorAll('[data-page]')];
			const pagelist = [];
			[...pages].forEach((page) => {
				const seq = page.getAttribute('data-page');
				pagelist[seq] = new Page(page, this);
			});
			return pagelist;
		}

		/**
		 * Register new custom rule
		 *
	  	 * @param string $name uname of new rule.
		 * @param object  $callbackFn call back function.
		 *
		 * @return void
		 */
		registerCustomRule(name, callbackFn, errorMessage) {
			const newRule = {};
			newRule.name = name;
			newRule.callbackFn = callbackFn;
			newRule.errorMessage = errorMessage || '';
			this.customRules.push(newRule);
		}

		/**
		 * Register new Async rule
		 *
	  	 * @param string $name uname of new rule.
		 * @param object  $callbackFn call back function.
		 *
		 * @return void
		 */
		registerAsyncRule(name, callbackFn) {
			const newRule = {};
			newRule.name = name;
			newRule.callbackFn = callbackFn;
			this.asyncRules.push(newRule);
		}

		/**
		 * Checking and opening next page on false init submit form
		 *
	  	 * @param int $sequence current page number
		 *
		 * @return void
		 */
		nextPage(sequence = false) {
			if (!sequence) sequence = this.activePage;
			const next = this.pages.find(this.findNextPage, sequence);
			if (next) {
				next.openPage();
				this.activePage++;
			} else {
				this.formSelector.submitForm();
			}
		}

		/**
		 * Search for element with higher sequence
		 *
	  	 * @param object $element page object
		 *
		 * @return bool
		 */
		findNextPage(element) {
			return element.sequence > this;
		}

		/**
		 * Search for element with lower sequence
		 *
	  	 * @param int $sequence current page number
		 *
		 * @return bool
		 */
		previousPage(sequence) {
			if (!sequence) sequence = this.activePage;
			let previous = { sequence: -1 };
			[...this.pages].forEach((page) => {
				if (previous.sequence < page.sequence && sequence > page.sequence) {
					previous = page;
				}
			});
			previous.openPage();
			this.activePage = previous.sequence;
		}

		/**
		 * Proccess submit depend on form mode;
		 *
		 *
		 * @return bool
		 */
		submitForm() {
			const this_ = this;
			this.eventHandlers.submitForm();
			if (this.mode === 'async') {
				this.sendPostAjax().then((e) => {
					const succesUrl = this_.form.getAttribute('data-succesUrl');
					const failUrl = this_.form.getAttribute('data-failUrl');
					const response = JSON.parse(e);
					if (response.status === 200) this_.eventHandlers.asyncSuccess(succesUrl, response);
					else this_.eventHandlers.asyncFailed(failUrl, response);
				});
			} else {
				this_.form.submit();
			}
		}

		getValues() {
			const form_data = new FormData();

			[...this.pages].forEach((page) => {

				const inputs = page.inputs;
				inputs.forEach((input) => {

					if (input instanceof Input) {
						if (input.input.type != 'file') { form_data.append(input.name, input.value); } else if (input.input.files[0]) { form_data.append(input.name, input.input.files[0], input.input.files[0].name); }
					}
					if (input instanceof Uploader) {
						[...input.list].forEach((file, k) => {
							form_data.append(`${input.name}[${k}]`, file.file, file.name);
						});
					}
				});
			});

			let inputs = this.form.querySelectorAll('input[type="hidden"]');
			[...inputs].forEach((input) => {
				form_data.append(input.name, input.value);
			});

			if (this.hasCaptcha) form_data.append('g-recaptcha-response', grecaptcha.getResponse(this.captcha.widgetId));

			return form_data;
		}

		getHiddenValues() {
			const data = {};

			return data;
		}

		/**
		 * Colect all form inputs and send to index.php
		 *
		 *
		 * @return object XHR Promise
		 */

		sendPostAjax() {
			const data = this.getValues();

			return new Promise((resolve, reject) => {
				const xhr = new XMLHttpRequest();
				xhr.open('POST', `${this.baseurl}index.php?`);
				xhr.onload = () => resolve(xhr.responseText);
				xhr.onerror = () => reject(xhr.statusText);
				xhr.send(data);
			});
		}
	}

	if (window.ValidatorForm === undefined) {
		window.ValidatorForm = ValidatorForm;
	} else {
		throw new Error('ERROR: ValidatorFormcall multiple times');
	}
})();

/* eslint-disable */
import ValidatorEngine from 'validatorjs';

export default class Language {
	constructor(langCode, langList) {
		this.langcode = langCode;
		this.setLangList(langList);
	}

	setLangList(langList) {
		const standartList = ValidatorEngine.getMessages(this.langcode);
		if (typeof langList !== 'undefined') {
			[...Object.getOwnPropertyNames(langList)].forEach((val, idx, array) => {
				standartList[val] = langList[val];
			});
		}

		this.langlist = standartList;
	}

	getLangList() {
		return this.langlist;
	}
}

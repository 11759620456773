/* eslint-disable */
import { on, bind } from './utils';

export default class FileInput {
	constructor(file, uploader) {
		this.uploader = uploader;
		this.deleted = false;
		this.file = file;
		this.name = file.name;
		this.mime = file.type;
	}

	get value() {
		return this.file;
	}

	remove() {
		this.deleted = true;
		this.uploader.removeFile();
	}

	render() {
		return /* html */`<ul class="black-list"><li><div class="uploader-file"><p class="file-name">${this.name}</p><div class="remove" ${on('click', this, 'remove')} ><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="Frame 545">
		<rect id="Rectangle 221" x="3" width="3" height="3" transform="rotate(90 3 0)" fill="#151515"/>
		<rect id="Rectangle 227" x="3" y="15" width="3" height="3" transform="rotate(90 3 15)" fill="#151515"/>
		<rect id="Rectangle 225" x="17.9922" y="2.97754" width="21.2335" height="4.19871" transform="rotate(135 17.9922 2.97754)" fill="#151515"/>
		<rect id="Rectangle 230" x="17.9893" y="14.9932" width="4.23754" height="21.1879" transform="rotate(135 17.9893 14.9932)" fill="#151515"/>
		<rect id="Rectangle 223" x="18" width="3" height="3" transform="rotate(90 18 0)" fill="#151515"/>
		<rect id="Rectangle 229" x="18" y="15" width="3" height="3" transform="rotate(90 18 15)" fill="#151515"/>
		</g>
		</svg></div></div></li></ul>`;
	}
}

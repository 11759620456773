/* eslint-disable */
export default class Recaptcha {
	constructor(validator, element) {
		this.validator = validator;
		this.element = element;
		this.registerCallBack();
		this.instaled = typeof window.recaptcha === 'object' ? 1 : 0;
	}

	registerCallBack() {
		this.callBackName = `${this.validator.formName}Submit`;
		window[this.callBackName] = () => {
			this.validator.submitForm();
		};
	}

	init() {
		this.instaled === 0 ? this.install() : this.run();
	}

	run() {
		if (!this.render) { this.renderCaptcha(); }
		window.grecaptcha.reset(this.widgetId);
		window.grecaptcha.execute(this.widgetId);
	}

	register() {
		this.onloadCallBackFn = `${this.validator.formName}afterLoad`;
		window[this.onloadCallBackFn] = this.afterInstall();
	}

	install() {
		this.register();
		const scriptTag = document.createElement('script');
		scriptTag.setAttribute('src', `https://www.google.com/recaptcha/api.js?onload=${this.onloadCallBackFn}`);
		document.body.appendChild(scriptTag);
	}

	afterInstall() {
		return () => {
			this.instaled = 1;
			this.run();
		};
	}

	renderCaptcha() {
		this.siteKey = this.element.getAttribute('data-siteid');
		this.theme = 'light';
		this.dataSize = 'invisible';
		this.widgetId = window.grecaptcha.render(this.element, {
			sitekey: this.siteKey,
			theme: this.theme,
			size: this.dataSize,
			badge: 'inline',
			callback: this.callBackName,
		});
		this.render = 1;
	}
}

/* eslint-disable */
const hash = (length = 10) => [...Array(length)].map(() => (~~(Math.random() * 36)).toString(36)).join('');

const on = (event, entity, method) => {
	let name;
	do {
		name = `${method}_${hash(10)}`;
	} while (window[name] !== undefined);

	window[name] = (e, v) => {
		e.preventDefault();
		e.stopPropagation();

		entity[method].call(entity, e, v);
	};

	return `on${event}="window.${name}(event,this)"`;
};

const bind = (entity, attribute, callbacks = []) => {
	if (typeof callbacks === 'string') callbacks = [callbacks];

	let name;
	do {
		name = `bind_${hash(10)}`;
	} while (window[name] !== undefined);

	window[name] = (e) => {
		entity[attribute] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
		callbacks.map((callback) => entity[callback].call(entity));
	};

	return `onchange="window.${name}(event)" value="${entity[attribute]}"`;
};
export {
	on,
	bind,
	hash,
};

/* eslint-disable */
export default class EventHandlers {
	/**
	 * Constructor
	 *
	 * @param string selector id or element address schould be uniqe
	 * @return Class EventHandlers
	 */
	constructor(validator) {
		this.validator = validator;
	}

	submitForm() {
		return true;
	}

	openPage(element) {
		element.classList.remove('close');
	}

	closePage(element) {
		element.classList.add('close');
	}

	lockSubmit(elements) {
		[...elements].forEach((element) => {
			element.classList.add('disabled');
		});
	}

	asyncSuccess(url, response) {
		if (url) window.location.href = url;
		else window.location.reload();
	}

	asyncFailed(url, response) {
		if (url) window.location.href = url;
		else window.location.reload();
	}

	unlockSubmit(elements) {
		[...elements].forEach((element) => {
			element.classList.remove('disabled');
		});
	}

	lockNext(elements) {
		[...elements].forEach((element) => {
			element.classList.add('disabled');
		});
	}

	unlockNext(elements) {
		[...elements].forEach((element) => {
			element.classList.remove('disabled');
		});
	}

	removeErrorsText(wrapper) {
		wrapper.innerHTML = '';
	}

	toogleWrapperClass(wrapper, toggle) {
		if (toggle) { wrapper.element.classList.add(wrapper.className); } else { wrapper.element.classList.remove(wrapper.className); }
	}

	printErrorsText(wrapper, errors) {
		let errorsHtml = '';
		[...errors].forEach((error) => {
			const message = document.createElement('span');
			message.innerHTML = error;
			errorsHtml += message.outerHTML;
		});
		wrapper.innerHTML = errorsHtml;
	}
}

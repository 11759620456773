/* eslint-disable */
import ValidatorEngine from 'validatorjs';
import { on, bind } from './utils';
import FileInput from './FileInput';

export default class Uploader {
	constructor(element, page) {
		this.element = element;
		this.page = page;
		this.validator = page.validator;
		this.name = this.element.getAttribute('data-name');
		this.total_max_size = this.element.getAttribute('data-total-max-size') * 1024;
		this.accept = this.element.getAttribute('data-accepted');
		this.file_max_size = this.element.getAttribute('data-file-max-size') * 1024;
		this.max_amount = this.element.getAttribute('data-max-amount');
		this.label = this.element.getAttribute('data-label');
		this.required = this.element.getAttribute('data-required') || false;
		this.list = [];
		this.errorMessage = [];
		this.index = 0;
		this.render();
	}

	get value() {
		const filelist = [];
		[...this.list].forEach((file) => {
			filelist.push(file.file);
		});
		return filelist;
	}

	get fileSchema() {
		return `filemime:${this.accept}|filesize:${this.file_max_size}`;
	}

	get schema() {
		return this.required ? 'uploadernotempty' : '';
	}

	openFileModal() {
		this.element.querySelector('[data-proxy]').click();
	}

	addFile(event, input) {
		if (input.files.length == 0) return;
		this.validate(input.files);
	}

	validate(filelist) {
		const _this = this;
		this.errorMessage = [];
		return new Promise((resolve) => {
			ValidatorEngine.useLang(_this.validator.lang);

			let langList = {};

			[..._this.validator.customRules].forEach((rule) => {
				ValidatorEngine.register(rule.name, rule.callbackFn, rule.errorMessage);
			});

			[..._this.validator.asyncRules].forEach((rule) => {
				ValidatorEngine.registerAsync(rule.name, rule.callbackFn);
			});

			if (_this.validator.langList[_this.validator.lang]) {
				langList = 	_this.validator.langList[_this.validator.lang].getLangList();
			} else {
				langList = _this.validator.getMessages('lang_code');
			}

			ValidatorEngine.setMessages(_this.validator.lang, langList);

			const total = _this.list.map((item) => item.file.size).reduce((prev, curr) => prev + curr, 0);
			if (filelist[0].size + total > _this.total_max_size) {
				_this.errorMessage.push(typeof langList.total_max_size !== 'undefined' ? langList.total_max_size : langList.filesize);
				_this.render();
				return false;
			}

			const amount = _this.list.length;
			if (1 + amount > _this.max_amount) {
				_this.errorMessage.push(typeof langList.max_amount !== 'undefined' ? langList.max_amount : 'To many file');
				_this.render();
				return false;
			}

			const value = {};
			value.uploader = filelist;
			const schema = {};
			schema.uploader = _this.fileSchema;
			const validation = new ValidatorEngine(value, schema);
			validation.passes(() => {
				_this.list.push(new FileInput(filelist[0], _this));
				_this.errorMessage = [];
				_this.render();
				resolve(true);
			});
			validation.fails(() => {
				_this.errorMessage = validation.errors.errors.uploader;
				_this.render();
				resolve(false);
			});
		});
	}

	removeFile() {
		const newlist = [];
		[...this.list].forEach((file) => {
			if (!file.deleted) newlist.push(file);
		});
		this.list = newlist;
		this.render();
	}

	render() {
		let list = /* html */ '';
		[...this.list].forEach((record) => {
			list += record.render();
		});

		[...this.errorMessage].forEach((msg) => {
			msg;
			list += /* html */ `<span class="file-error">${msg}<span>`;
		});

		const html = /* html */ `<div>${list}</div><span class="btn btn-secondary" title="Wgraj swoje CV!"  ${on('click', this, 'openFileModal')} >Wybierz plik z dysku</span><input style="display:none;" data-proxy type="file" name="${this.name}proxy" accept="${this.name}" ${on('change', this, 'addFile')} >`;
		this.element.innerHTML = html;
	}

	onErrorsChanged(validBeforeSubmit) {
		if (validBeforeSubmit === true) {
			this.wasChanged = true;
		}
		this.errorMessage = [];
		if (this.hasErrors()) { this.errorMessage = this.errorMessage.concat(this.page.errors.get(this.name)); }
		this.render();
	}

	hasErrors() {
		const errorsBag = this.page.errors;
		const { name } = this;

		if (errorsBag.has(name) && this.wasChanged) { return true; }
		return false;
	}
}

/* eslint-disable */
export default class Action {
	constructor(action, page, event) {
		this.action = action;
		this.event = event;
		this.validatorClass = page.validator;
		this.pageClass = page;
		this.lockableAction = ['submit', 'next'];
		this.lockable = this.lockableAction.indexOf(this.action) >= 0;
		this.actionElements = this.getActionElements();
		this.bindAction();
	}

	getActionElements() {
		const actionElements = this.pageClass.pageElement.querySelectorAll(`[data-action="${this.action}"] , [data-custom-action="${this.action}"] `);
		return actionElements;
	}

	bindAction() {
		if (typeof this[`${this.action}Action`] === 'function' && this.actionElements.length !== 0) {
			[...this.actionElements].forEach((element) => {
				element.addEventListener(this.event, this[`${this.action}Action`].bind(this));
			});
		} else {
			console.warn(`Action not found : ${this.action}Action`);
		}
	}

	submitAction(event) {
		event.preventDefault();
		event.returnValue = false;

		this.pageClass.validate(true).then((value) => {
			if (value) {
				if (this.validatorClass.hasCaptcha) {
					this.validatorClass.captcha.init();
				} else {
					this.pageClass.submitForm();
				}
			} else {
				this.dangerInputs();
				this.disableAction();
				return false;
			}
		}).catch((value) => {
			console.warn(value);
		});
	}

	nextAction(event) {
		event.preventDefault();

		this.pageClass.validate().then((value) => {
			if (value) {
				this.pageClass.validator.nextPage(this.pageClass.sequence);
				this.pageClass.closePage();
			} else {
				this.disableAction();
				return false;
			}
		})
			.catch((value) => {
				console.warn(value);
			});
	}

	dangerInputs() {
		this.pageClass.inputs.map((el) => {
			if (!el.textWrappers) return;
			const input = el.textWrappers[0].classList;
			if (input.contains('error--show') || input.contains('v-visible')) {
				el.input.classList.add('form-control--error');
			}
		});
	}

	previousAction(event) {
		event.preventDefault();
		this.pageClass.closePage();
		this.pageClass.validator.previousPage(this.pageClass.sequence);
	}

	disableAction() {
		if (!this.lockable) return false;
		if (typeof this.pageClass.validator.eventHandlers[`lock${this.capitalize(this.action)}`] === 'function' && this.actionElements.length !== 0) {
			this.pageClass.validator.eventHandlers[`lock${this.capitalize(this.action)}`](this.actionElements);
		} else {
			console.warn(`Animation not found : lock${this.capitalize(this.action)}`);
		}
	}

	unlockAction() {
		if (!this.lockable) return false;
		if (typeof this.pageClass.validator.eventHandlers[`lock${this.capitalize(this.action)}`] === 'function' && this.actionElements.length !== 0) {
			this.pageClass.validator.eventHandlers[`unlock${this.capitalize(this.action)}`](this.actionElements);
		} else {
			console.warn(`Animation not found : unlock${this.capitalize(this.action)}`);
		}
	}

	capitalize(string) {
		return string && string[0].toUpperCase() + string.slice(1);
	}
}
